import React, { useRef, useState } from 'react';
import useIntersectionObserver from '../include/hooks/useIntersectionObserver';
// cubic-bezier(0.455, 0.030, 0.515, 0.955)
export default ({ 
  className,
  opacityDuration="400ms", 
  opacityDelay="0ms", 
  fromOpacity=0, 
  offsetDuration="400ms", 
  offsetDelay="0ms", 
  fromOffsetY=0, 
  threshold=0, 
  active=true, 
  onShow, 
  children 
})  => {

  const ref = useRef();
  const [ isVisible, setIsVisible ] = useState(false);

  useIntersectionObserver({
    target: ref,
    threshold: threshold,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          setIsVisible(true);
          if (onShow) {
            onShow();
          }
        }
        observerElement.unobserve(ref.current);
      }
    }
  });

  return <div 
    className={className}
    ref={ref} 
    style={{ 
      opacity: active && isVisible ? 1 : fromOpacity,
      zIndex: 8, 
      transform: `translateY(${active && isVisible ? 0 : fromOffsetY}px)`,
      transition: `opacity ${opacityDuration} ease-out ${opacityDelay}, transform ${offsetDuration} ease-out ${offsetDelay}` 
    }}
  >
    { children }
  </div>;
}
