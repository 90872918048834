import styled from 'styled-components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as MenuButton } from '../assets/menu.svg';
import { ReactComponent as MenuCloseButton } from '../assets/menuClosed.svg';
import { ReactComponent as Logo } from '../assets/logoBlack.svg';

const Menu = styled.div`
  .menuButton, .menuCloseButton {
    cursor: pointer;
    z-index: 999;
    line {
      stroke: black;
    }
  }

  &.noSlide nav, .menuButton, .menuCloseButton {
    transition: opacity 0.4s ease;    
    position: fixed;
  }

  &.noSlide nav, .menuCloseButton {
    opacity: 0;
  }

  &.withSlide {
    nav {
      transition: transform 0.4s;
      position: fixed;
    }

    nav {
      transform: translateY(-100%);
    }  
  }


  .menuButton {
    opacity: 1;
    top: 38px;
    left: 21px;

    line {
      transition: stroke 0.2s ease;
    }
  }

  .menuCloseButton {
    top: 44px;
    left: 28px;
    width: 12px;
    height: 12px;
  }

  nav {
    z-index: 998;
    ul {
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 12px;

      li {
        display: block;
        margin-left: 37px;
      }
    }

    a {
      color: black;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 2px;
    }
  }

  &.noLogo {
    nav {
      top: 44px;
      left: 40px;
    }
  }

  &.withLogo {
    nav {
      background-color: white;
      top: 0;
      left: 0;
      height: 162px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      ul li:first-child {
        margin-left: 0;
      }
    }

    .logo {
      margin: 38px 0 32px;
    }
  }

  &.active {
    nav, .menuCloseButton {
      opacity: 1;
    }

    nav {
      transform: translateY(0px);
    }

    .menuButton {
      opacity: 0;
    }
  }

  &.white {
    &.noLogo .menuCloseButton, .menuButton {
      line {
        stroke: white;
      }
    }

    &.noLogo nav a {
      color: white;
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    .menuButton {
      top: 66px;
      left: 56px;
    }

    .menuCloseButton {
      top: 73px;
      left: 63px;
    }

    &.noLogo {
      nav {
        top: 73px;
        left: 75px;
      }
    }

    &.withLogo {
      nav ul {
        position: absolute;
        top: 73px;
        left: 112px;
      }

      .logo {
        margin-top: 65px;
      }
    }

  }

`


export default ({ white=false, logo=false, slide=false }) => {
  const [ active, setActive ] = useState(false);
  const [ activeClass, setActiveClass ] = useState('');
  let timeout = null;

  const toggleActive = () => {
    clearTimeout(timeout);
    if (active) {
      setActiveClass('');
      timeout = setTimeout(() => setActive(false), 500);
    } else {
      setActive(true);
      timeout = setTimeout(() => setActiveClass('active'), 5);
    }
  }


  return (
    <Menu className={`${activeClass} ${white ? 'white' : ''} ${logo ? 'withLogo':'noLogo'} ${slide ? 'withSlide':'noSlide'}`}>
      <MenuButton className={`menuButton `} onClick={toggleActive} />
      <MenuCloseButton className={`menuCloseButton`} onClick={toggleActive} />
      { active && <nav>
        { logo && <Link to="/" className="logo"><Logo /></Link> }
        <ul>
          <li><Link to="/work">Work</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/contact">Contact</Link></li>
        </ul>
      </nav>
      }  
    </Menu>
  );
}
