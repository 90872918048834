import styled from 'styled-components';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import MediaContainer from './MediaContainer';


const VideoContainer = styled.div`
  .portrait {
    display: none;
  }

  @media (orientation: portrait) {
    &.hasPortrait {
      .landscape {
        display: none;
      }
      .portrait {
        display: block;
      }
    }
  }
`

export default ({ src, srcPortrait=null, poster, posterPortrait, className, aspectRatio, onLoad, active=true })  => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const portraitRef = useRef();
  const landscapeRef = useRef();

  const isLandscape = document.body.clientWidth >= document.body.clientHeight;

  const canPlayThrough = useCallback((a) => {
    setTimeout(() => {
      setIsLoaded(true);
      if (onLoad) {
        onLoad();
      }
    }, 5); // make sure animation is triggered
  }, [setIsLoaded, onLoad]);

  useEffect(() => {
    const pCur = portraitRef.current;
    const lCur = landscapeRef.current;

    if (pCur) {
      pCur.addEventListener('canplaythrough', canPlayThrough);
    }
    if (lCur) {
      lCur.addEventListener('canplaythrough', canPlayThrough);
    }

    return () => {
      if (pCur) {
        pCur.removeEventListener('canplaythrough', canPlayThrough);
      }
      if (lCur) {
        lCur.removeEventListener('canplaythrough', canPlayThrough);
      }
    }
  }, [ landscapeRef, portraitRef, canPlayThrough ]);

  return <VideoContainer className={`${className} ${srcPortrait ? 'hasPortrait' : ''} ${isLoaded ? 'loaded' : ''}`}>
    <MediaContainer className={className} aspectRatio={aspectRatio}>
      { src && active && <>
        { (!srcPortrait || isLandscape) && <video  ref={landscapeRef} className="landscape" muted autoPlay playsInline loop src={src} poster={poster} /> }
        { srcPortrait && !isLandscape && <video ref={portraitRef} className="portrait" muted autoPlay playsInline loop src={srcPortrait} poster={posterPortrait} /> }
        </>
      }
    </MediaContainer>
  </VideoContainer>
}