import styled from 'styled-components';
import React from 'react';

const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  background: none;//rgba(0, 0, 0, 0.05);
  display: block;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.full {
      // transition: opacity 1000ms ease 0ms, transform 2400ms ease 0ms;
    }

    &.thumb {
      display: none;
      filter: blur(20px);
      transform: scale(1.1);
      transition: visibility 0ms ease 400ms;
      visibility: visible;
    }
  }

`

export default ({ className, aspectRatio, children }) => {
  return <Wrap className={className} style={ aspectRatio ? {paddingBottom: `${1/aspectRatio*100}%`} : null}>{ children }</Wrap>
}