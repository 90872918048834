import styled from 'styled-components';
import React, { useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Page = styled.div`
  margin-bottom: 73px;
  min-height: calc(100% - 342px);

  @media (min-width: ${props => props.theme.bp.large}) {
    min-height: calc(100% - 316px);
  }

`
export default ({ title, header, children }) => {
  const titleSuffix = "Donohoe Living Landscapes";

  useEffect(() => {
    window.scrollTo({ top: 0 /*, behavior: 'smooth'*/ }) 
  }, [])
  
  useEffect(() => {
    if (title === false) {
      document.title = titleSuffix;
    } else if (title) {
      document.title = `${title} - ${titleSuffix}`;
    }
  }, [ title, titleSuffix ])

  return <>
    <Header { ...header }/>
    <Page>{ children }</Page>
    <Footer />
  </>
}