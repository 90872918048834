import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './Menu';
import { ReactComponent as Logo } from '../assets/logoBlack.svg';

const Header = styled.header`
  width: 100%;
  position: relative;
  height: 115px;
  display: flex;
  justify-content: center;


  .logo {
    display: block;
    margin-top: 38px;
  }

  &.overlay {
    position: absolute;
    z-index: 995;
  }

  &.white {
    .logo {
      line, path {
        fill: white;
      }      
    }
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    height: 165px;

    .logo {
      margin-top: 65px;
    }
  }

`
export default ({ overlay=false, white=false }) => {
  return (
    <>
      <Header className={`${overlay ? 'overlay': ''} ${white ? 'white' : ''}`}>
        <Link to="/" className="logo"><Logo /></Link>
      </Header>
      <Menu logo white={white} />
    </>
  );
}
