import React, { useState } from 'react';

import TransitionBlock from './TransitionBlock';
import Image from './Image';
import Video from './Video';

export default ({ image=null, video=null, defaultAspectRatio=null, opacityDuration="0.4s", offsetDuration="0.4s", offsetDelay="0s", fromOffsetY=40 }) => {
  const [ isLoaded, setIsLoaded ] = useState(false);
  return <TransitionBlock {...{opacityDuration, offsetDuration, offsetDelay, fromOffsetY}}  active={isLoaded}>
    { video 
      ? <Video src={video.url} onLoad={() => setIsLoaded(true)} aspectRatio={video.width/video.height} />
      : <Image src={image?.landscape || image} srcPortrait={image?.portrait} defaultAspectRatio={defaultAspectRatio} onLoad={() => setIsLoaded(true)} />
    }
  </TransitionBlock>
}