const headers ={
  'Content-Type': 'application/json'
}; 

const host = 'https://wordpress.donohoelivinglandscapes.com';
const prefix = '/wp-json/wp/v2';

const respond = request => {
  return request.then(response => {
    if (!response.ok) {
      throw new Error('The request failed.');
    }
    return response.json();
  }).catch(err => {
    throw err;
  });    
}

const request = (path) => {
  const options = {
    headers,
    // cache: 'force-cache'
  };
  const url = path.indexOf('/') === 0 ? host + prefix + path : path;
  return respond(fetch(url, options));
}

const one = req => {
  return req.then(result => result && result.length > 0 ? result[0] : null);
}

const page = slug => {
  return one(request(`/pages?slug=${slug}`));
}

export default {
  request(url) {
    return request(url);
  },

  homePage() {
    return page("home");
  },

  aboutPage() {
    return page("about");
  },

  contactPage() {
    return page("contact");
  },

  careersPage() {
    return page("careers");
  },

  careers() {
    return request("/career?per_page=100");
  },

  projects() {
    return request("/project?per_page=100");
  },

  project(slug) {
    return one(request(`/project?slug=${slug}`));
  },


}
