import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import WP from '../services/wordpress';
import Page from './Page';
import HtmlBlock from '../components/HtmlBlock';
import MediaBlock from '../components/MediaBlock';

const Contact = styled.div`

  .html {
    margin-top: 72px;

    @media (min-width: ${props => props.theme.bp.medium}) {
      p {
        width: 50%;
        float: left;
      }
    }
  }

  >section {
    display: flex;
    flex-direction: column;
    align-items: center;

    >div {
      width: 100%;
      max-width: 1044px;
    }

  }
`

export default () => {
  const [ page, setPage ] = useState();

  useEffect(() => {
    WP.contactPage().then(result => {
      setPage(result);
    });
  }, [ setPage ]);

  return (
    <Page title="Contact">
      <Contact>
        <section><MediaBlock image={page?.acf.image} section={true} defaultAspectRatio={1044/606} /></section>
        { page && <HtmlBlock html={page.content.rendered} /> }
      </Contact>
    </Page>
  )
}