import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import WP from '../services/wordpress';
import ProjectThumb from '../components/ProjectThumb';
import Page from './Page';

const Projects = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 21px;

  .projectThumb {
    margin-bottom: 69px;

    &:last-child {
      margin-bottom: 25px;

      @media (min-width: ${props => props.theme.bp.large}) {
        margin-bottom: 56px;
      }
    }
  }

`

export default () => {
  const [ projects, setProjects ] = useState([]);

  useEffect(() => {
    WP.projects().then(result => setProjects(result));
  }, [ setProjects ]);

  return (
    <Page title="Work">
      <Projects>
        { projects
            .filter(project => project.acf.is_listed)
            .map(project => <ProjectThumb key={ project.id } project={project} />) }
      </Projects>
    </Page>
  )
}