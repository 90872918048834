import styled from 'styled-components';
import React from 'react';
import ReactDOM from 'react-dom';
import TransitionBlock from './TransitionBlock';
import { ReactComponent as CloseButton } from '../assets/menuClosed.svg';

const Fullscreen = styled.div`
  background: black;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  
  .videoContainer, video {
    width: 100%;
    height: 100%;
  }

  .closeButton {
    z-index: 9999;
    position: absolute;
    right: 30px;
    top: 40px;
    width: 18px;
    height: 18px;
    cursor: pointer;

    line {
      stroke: white;
    }
  }
`

export default ({ src, active=false, toggleActive })  => {
  document.body.classList[active ? 'add' : 'remove']("modal-active");

  return !active ? null : ReactDOM.createPortal(
    <Fullscreen>
      <TransitionBlock className="videoContainer" opacityDuration="2s" active={active}>
          <video className="heroItem" playsInline autoPlay controls><source src={src} /></video>
      </TransitionBlock>
      <CloseButton onClick={toggleActive} className="closeButton" />
    </Fullscreen>
  , document.body);

}