import React, { useState } from 'react';
import MediaContainer from './MediaContainer';

export default ({ src, srcPortrait=null, className, defaultAspectRatio=null, onLoad, active=true })  => {
  const [ isLoaded, setIsLoaded ] = useState(false);

  const isLandscape = document.body.clientWidth >= document.body.clientHeight;
  const image = !isLandscape && srcPortrait ? srcPortrait : src;
  const aspectRatio = image ? image.width / image.height : defaultAspectRatio;


  const full = image?.url;
  const thumb = image?.sizes.medium;
  const caption = image?.caption;

  const onImageLoad = () => {
    setTimeout(() => {
      setIsLoaded(true);
      if (onLoad) {
        onLoad();
      }
    }, 5); // make sure animation is triggered
  }

  return <MediaContainer className={className} aspectRatio={aspectRatio}>
      { src && active && <>
          <img
            className="image thumb"
            alt={caption}
            src={thumb}
            style={{ visibility: isLoaded ? "hidden" : "visible" }}
          />
          <img
            onLoad={onImageLoad}
            className="image full"
            // style={{ opacity: isLoaded ? 1 : 0, transform: `translateY(${isLoaded ? 0 : 100}px)` }}
            alt={caption}
            src={full}
          />
        </>
      }
    </MediaContainer>
}