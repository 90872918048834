import styled from 'styled-components';
import React, { useRef ,useState, useEffect } from 'react';

const HtmlBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: max-height 0.5s;
  overflow: hidden;


  .html {
    width: 100%;
    max-width: 874px;
    padding: 0 21px;
    font-weight: 200;
    font-size: 22px;
    line-height: 30px;  
  }

  &.dark {
    background-color: black;
    color: white;
  }

  &.dark, &.light {
    .html {
      padding-top: 72px;
      padding-bottom: 68px;
    }
  }

  h1, h2, h3, h4 {
    font-weight: 400;
    line-height: 1.1364em;
    margin-bottom: 20px;
  }

  h1, h2 {
    text-transform: uppercase;
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
  }

  p {    
    font-weight: 200;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  .content {
    display: inline;
  }

  .readMoreButton {
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
  }

  &.active {
    .readMoreButton {
      display: block;
      margin-top: 1em;
    }
  }

  ul {
    list-style: none;

    padding-left: 33px;

    li {
      margin-bottom: 1em;

      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: -33px;
        width: 17px;
        top: 15px;
        height: 1px;
        background-color: black;

      }
    }
  }
`

export default ({ html, className='', theme=false, readMore=false, maxLength=false, moreActive=false, showMoreActive=false, readLess=true, slideActive=true, forceClose=false }) => {
  const ref = useRef();
  const htmlRef = useRef();
  const [ htmlContent, setHtmlContent ] = useState('');
  const [ excerptHeight, setExcerptHeight ] = useState();
  const [ active, setActive ] = useState(moreActive);
  const [ style, setStyle ] = useState();

  useEffect(() => {
    setActive(moreActive);
  }, [moreActive]);

  useEffect(() => {
    if (!readMore || active) {
      setHtmlContent(html);
    } else {
      let content = html;

      if (!active) {
        let pStart = html.toLowerCase().indexOf('<p>');
        content = html.substr(0, html.toLowerCase().indexOf('</p>')).replace('<p>', '');
        if (maxLength !== false) {
          content = content.substr(0, pStart + maxLength);
        }
        content += (content[content.length-1] === '.' ? '..' : '...');
      }

      if (slideActive) {
        setStyle({ maxHeight: excerptHeight });
      }

      setTimeout(() => {
        setHtmlContent(content);
      }, slideActive ? 400 : 0);
    }

  }, [ moreActive, html, active, maxLength, slideActive, excerptHeight, readMore ])


  const toggleActive = () => {
    const toggledActive = !active;

    if (!excerptHeight) {
      setExcerptHeight(ref.current.clientHeight);
    }

    if (toggledActive && slideActive) {
      setStyle({ maxHeight: ref.current.clientHeight });
    }

    if (slideActive) {
     setActive(toggledActive);
    }

    if (showMoreActive) {
      showMoreActive(toggledActive);
    }
  }

  useEffect(() => {
    if (active && slideActive) {
      setStyle({ maxHeight: htmlRef.current.clientHeight });
    }  
  }, [ active, htmlContent, slideActive ]);

  return <HtmlBlock ref={ref} style={style} className={`${className} ${theme || ''} ${readMore ? 'readmore' : ''} ${active ? 'active' : ''}`}>
    <div ref={htmlRef} className="html">
      <div className="content" dangerouslySetInnerHTML={{ __html: htmlContent }} />
      { readMore && (readLess || !active) && <span className="readMoreButton" onClick={toggleActive}>{ active ? '(Read less)' : '(Read more)' }</span> }
    </div>
  </HtmlBlock>
}