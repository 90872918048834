import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WP from '../services/wordpress';

import Menu from '../components/Menu';
import Hero from '../components/Hero';
// import TransitionBlock from '../components/TransitionBlock';
import Video from '../components/Video';

export default () => {
  const [ page, setPage ] = useState();
  // const [ isLoaded, setIsLoaded ] = useState(false);  
  const [ startIndex, setStartIndex ] = useState(0);

  useEffect(() => {
    document.title = "Donohoe Living Landscapes"; 
  });

  // useEffect(() => {
  //   setStartIndex(Math.floor(Math.random()*6));
  // }, [setStartIndex]);

  useEffect(() => {
    WP.homePage().then(result => {
      setPage(result);
    });
  }, [ setPage ]);

  return <>
    { page ? (
        <Link to="/work">
          <Hero>
            <Video src={`${page.acf.video.landscape.url}#t=${startIndex*3}`} srcPortrait={`${page.acf.video.portrait.url}#t=${startIndex*3}`} poster={page.acf.landscape_images[startIndex]} className="heroItem" />
          </Hero>
        </Link>
    ) : null }
    <Menu white />
  </>

}