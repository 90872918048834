import styled, { ThemeContext } from 'styled-components';
import React, { useContext, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import WP from '../services/wordpress';
import Page from './Page';
import HtmlBlock from '../components/HtmlBlock';
import MediaBlock from '../components/MediaBlock';
import ModalVideo from '../components/ModalVideo';
import { ReactComponent as CloseButton } from '../assets/menuClosed.svg';


const Team = styled.section`
  margin: 72px auto;
  width: 100%;
  max-width: 1044px;
  position: relative;
  padding-top: 180px;

  @media (min-width: ${props => props.theme.bp.medium}) {
    padding-top: 0;
  }

`

const TeamPoint = styled.div`
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
  height: 15px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 15px;


  @media (min-width: ${props => props.theme.bp.medium}) {
    height: 13px;
    width: 13px;
  }

`

const TeamMembers = styled.div`

`

const TeamSelection = styled.div`
  position: relative;
`



const TeamMemberWrap = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 760px;
  padding: 20px 21px;
  opacity: 0;
  transition: opacity 600ms;

  @media (min-width: ${props => props.theme.bp.medium}) {
    background-color: rgba(255, 255, 255, 0.87);
  }

  &.visible {
    opacity: 1;
    z-index: 9;
  }

  .html {
    padding: 0;
  }

  &.visible:not(.modal),
  &.modal.active {
    .closeButton {
      display: block;
    }
  }

  &.modal {
    background-color: rgba(255, 255, 255, 0.95);
    display: none; 
    transform: translateX(0);
    top: 0;
    left: 0;

    &.basic {
      position: fixed;
      height: 100vh;
      overflow: auto;
      z-index: 99999;
    }
  }



  &.modal.active {
    display: block;
  }

  h2 {
    margin-bottom: 0;
  }

  h2, h3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
  }


  .closeButton {
    cursor: pointer;
    top: 39px;
    right: 21px;
    width: 12px;
    height: 12px;
    position: absolute;
    display: none;
  }

  @media (min-width: ${props => props.theme.bp.medium}) {
    padding: 63px 70px;
    top: 85px;
    overflow: auto;
    max-height: calc(100% - 85px - 85px);

    .closeButton {
      right: 32px;
    }
  }

`

const Awards = styled.section`
  margin: 72px auto;
  width: 100%;
  max-width: 1086px;
  padding: 0 21px;
  font-size: 16px;
  line-height: 19px;

  >div {
    font-weight: 700;
  }

`

const BrandVideoWrap = styled.div`
  margin: 38px auto;
  width: 100%;
  max-width: 874px;
  padding: 0 21px;

  button {
    background: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 35px;
    text-transform: uppercase;
  }
`

const Modal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
}

const TeamMember = ({ html, visible=false, onClose=null }) => {
  const modalRef = useRef();
  const theme = useContext(ThemeContext);
  const [bioActive, setBioActive] = useState(false);
  const [isModal, setIsModal] = useState(true);

  const mediumBreakpoint = parseInt(theme.bp.medium);

  useEffect(() => {
    setIsModal(document.body.clientWidth < mediumBreakpoint);
  }, [bioActive, setIsModal, mediumBreakpoint]);

  const closeClicked = () => {
    setBioActive(false)
    if (onClose && !isModal) {
      onClose();
    }
  }

  const modalActive = bioActive && isModal;
  const modalOpen = modalActive;
  document.body.classList.toggle("modal-active", modalActive);
  document.querySelector("html").classList.toggle("modal-active", modalActive);


  const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
  const isIphone = navigator.userAgent.indexOf("iPhone") !== -1;
  const isMobileIosSafari = isSafari && isIphone;

  document.body.classList.toggle("ios-modal-active", modalActive && isMobileIosSafari);



  // const Component = <TeamMemberWrap className={`${visible ? 'visible' : ''} ${bioActive ? 'active' : ''} ${isModal ? 'modal' : ''}`}>
  //   <HtmlBlock html={html} readMore readLess={false} maxLength={isModal ? 50 : false} moreActive={bioActive} showMoreActive={setBioActive} slideActive={!isModal} />
  //   <CloseButton className="closeButton" onClick={ closeClicked }/>
  // </TeamMemberWrap>;

  // const Modal = isModal && ReactDOM.createPortal(Component, document.body);

  return <>
    <TeamMemberWrap className={`${visible ? 'visible' : ''} ${bioActive ? 'active' : ''}`}>
      <HtmlBlock html={html} readMore readLess={false} maxLength={isModal ? 50 : false}  showMoreActive={setBioActive} slideActive={!isModal} />
      <CloseButton className="closeButton" onClick={ closeClicked }/>
    </TeamMemberWrap>  
    { isModal && <Modal>
      <TeamMemberWrap ref={modalRef} className={`modal ${visible ? 'visible' : ''} ${bioActive ? 'active' : ''} ${isMobileIosSafari ? 'ios' : 'basic'}`}>
        <HtmlBlock html={html} maxLength={false} moreActive={bioActive} showMoreActive={setBioActive} slideActive={false} />
        <CloseButton className="closeButton" onClick={ closeClicked }/>
      </TeamMemberWrap>  
    </Modal> }
  </>
  // return bioActive && isModal ? ReactDOM.createPortal(Component, document.body) : Component;
}

const BrandVideo = ({ video=null }) => {
  const [active, setActive] = useState(false);

  const toggleActive = () => setActive(!active);

  return !video ? null : <BrandVideoWrap>
    <button onClick={() => setActive(true)}>Watch the video</button>
    <ModalVideo src={video.url} active={active} toggleActive={toggleActive} />
  </BrandVideoWrap>
}

export default () => {
  const theme = useContext(ThemeContext);
  const [ page, setPage ] = useState();
  const mediumBreakpoint = parseInt(theme.bp.medium);
  const [ activeTeamMember, setActiveTeamMember ] = useState(document.body.clientWidth < mediumBreakpoint ? 0 : null);

  useEffect(() => {
    WP.aboutPage().then(result => {
      setPage(result);
    });
  }, [ setPage ]);

  const bioClosed = () => {
    setActiveTeamMember(null);
  }

  return (
    <Page title="About">{ page && <>
      <HtmlBlock html={page.content.rendered} readMore />

      <BrandVideo video={page?.acf.brand_video} />

      <Team className>
        <TeamMembers>
        { page.acf.team.map((member, id) => <TeamMember 
          key={`member-${id}`} 
          visible={activeTeamMember === id} 
          html={`<h2>${member.name}</h2>${member.title?`<h3>${member.title}</h3>`:''} ${member.bio}`} onClose={bioClosed} />
        )}
        </TeamMembers>

        <TeamSelection>
          <MediaBlock image={page.acf.team_image} fromOffsetY={0} />

          { page.acf.team.map((member, id) => <TeamPoint 
            key={`point-${id}`} 
            onClick={() => setActiveTeamMember(id)} 
            style={{left: `${member.x_coord}%`, top: `${member.y_coord}%`}} />
          )}

        </TeamSelection>
      </Team>

      <Awards>
        <label>Awards</label>
        <div dangerouslySetInnerHTML={{ __html: page.acf.awards }} />
      </Awards>
    </>}
    </Page>
  )
}