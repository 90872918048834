import { useEffect } from 'react';

export default ({
  target,
  src = null,
  onIntersect,
  threshold = 0,
  rootMargin = "0px",
  enabled = true
}) => {

  useEffect(() => {
    const current = target.current;
    let observer;
    if (enabled) {
      observer = new IntersectionObserver(onIntersect, {
        rootMargin,
        threshold,
        root: src ? src.current : null
      });

      observer.observe(current);
    }

    return () => {
      if (enabled) {
        observer.unobserve(current);
      }
    };
  });
};
