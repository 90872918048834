import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/logoSquareWhite.svg';
import { ReactComponent as Instagram } from '../assets/instagramWhite.svg';
import { ReactComponent as Email } from '../assets/emailWhite.svg';
import { ReactComponent as ScrollUp } from '../assets/arrowUp.svg';


const Footer = styled.footer`
  background: black;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0 0 27px;

  a {
    color: inherit;
    text-decoration: none;
  }

  .brand, .links {
    display: flex;
    align-items: center;
    color: white;
    font-size: 12px;
    line-height: 16px;
  }

  .brand {
    margin: 0 21px;
    .copyright {
      margin-left: 24px;
      font-weight: 700;
    }
    svg {
      width: 43px;
      height: 43px;
    }
  }

  .links {
    margin: 32px 21px;
    justify-content: flex-end;
    a {
      display: block;
      margin-left: 30px;
      font-weight: 700;
      text-transform: uppercase;

      &:first-child {
        margin-left: 0;
      }

      &.icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .scrollUp {
    background: none;
    cursor: pointer;
    position: absolute;
    top: -72px;
    right: 11px;
    z-index: 10;
    outline: none;
  }

  @media (min-width: ${props => props.theme.bp.large}) {
    height: 78px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    .brand, .links {
      margin: 0 55px;

      .copyright {
        br {
          display: none;
        }
      }
    }

    .scrollUp {
      right: 42px;
    }
  }

`


export default ()  => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Footer>
      <div className="links">
        <Link to="/careers">Careers</Link>
        <a className="icon" href="https://www.instagram.com/donohoelivinglandscapes/" target="_blank" rel="noopener noreferrer"><Instagram /></a>
        <a className="icon" href="mailto:info@donohoelivinglandscapes.com"><Email /></a>
      </div>
      <div className="brand">
        <Logo />
        <div className="copyright">&copy; 2021 DONOHOE LIVING LANDSCAPES. <br/>All Rights Reserved.</div>
      </div>
      <button className="scrollUp" onClick={ scrollToTop }><ScrollUp /></button>
    </Footer>
  );
}
