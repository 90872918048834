import styled from 'styled-components';
import React, { useRef, useState, useEffect } from 'react';
import WP from '../services/wordpress';
import Page from './Page';
import HtmlBlock from '../components/HtmlBlock';
import MediaBlock from '../components/MediaBlock';
import ImageGroup from '../components/ImageGroup';
import ResponsiveVideo from "../components/ResponsiveVideo";
import useIntersectionObserver from '../include/hooks/useIntersectionObserver';

const Project = styled.div`
  section:not(.overview) {
    margin: 72px 0;
  }
`

const ProjectImage = styled.section`
  &.bleed {
    padding: 0 21px;

    @media (min-width: ${props => props.theme.bp.medium}) {
      padding: 0 4.02635%;
    }  
  }
`

const ProjectMeta = styled.section`
  padding: 0 21px 25px;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0;

  a {
    color: black;
  }

  dl {
    margin: 0;
  }

  dt {
  }

  dd {
    font-weight: 700;
    margin: 0 0 1em;
  }

  @media (min-width: ${props => props.theme.bp.medium}) {
    padding: 0 4.02635%;
  }  
`

export default ({ match }) => {
  const intersectRef = useRef();
  const [ project, setProject ] = useState();
  const [ projectOverview, setProjectOverview ] = useState();
  const [ menuIsWhite, setMenuIsWhite ] = useState(true);
  const slug = match.params.slug;

  useEffect(() => {
    WP.project(slug).then(result => {
      if (result?.acf?.sections?.length && result.acf.sections[0].acf_fc_layout === 'text_block') {
        setProjectOverview(result.acf.sections.shift());
      }
      setProject(result);
    });
  }, [ slug, setProject ]);

  useIntersectionObserver({
    target: intersectRef,
    threshold: 0,
    enabled: !!project,
    rootMargin: document.body.clientWidth < 1024 ? "-51px" : "-79px",
    onIntersect: ([{ isIntersecting }], observerElement) => {
      setMenuIsWhite(isIntersecting);
    }
  });

  return (
    <Page title={project?.title.rendered} header={{ overlay: true, white: menuIsWhite }}>
      <Project>
        <section ref={ intersectRef } className="overview">
          { project?.acf.video?.landscape &&
            <ResponsiveVideo video={project.acf.video} />
          }
          { !project?.acf.video?.landscape && <MediaBlock image={project?.acf.image} defaultAspectRatio={2560/1496} /> }
          { projectOverview && 
          <HtmlBlock theme={projectOverview.dark ? "dark" : "light"} html={`<h2>${projectOverview.title}</h2><p>${projectOverview.text}</p>`} />
          }
        </section>
        { project?.acf?.sections && project.acf.sections.map((section, idx) => {
          switch (section.acf_fc_layout) {
            case 'text_block':
              return <section key={`section-${idx}`}><HtmlBlock theme={section.dark ? "dark" : "light"} html={`<h2>${section.title}</h2><p>${section.text}</p>`} /></section>
            case 'image':
              return <ProjectImage key={`section-${idx}`} className={section.full_width ? 'bleed' : ''}><MediaBlock image={section} defaultAspectRatio={1366/844} /></ProjectImage>
            case 'image_group':
              return <ImageGroup 
                key={`section-${idx}`} 
                layout={section.layout} 
                text={section.text} 
                image1={section.image1} 
                image2={section.image2} 
                image3={section.image3} 
                video1={section.video1} 
                video2={section.video2} 
                video3={section.video3} 
                flipX={section.orientation.indexOf("horizontal")>=0} 
                flipY={section.orientation.indexOf("vertical")>=0} 
              />
            default:
              return null;
          }
        }) }
        { project?.acf.footnotes &&
        <ProjectMeta>
          <dl>
            {
              project.acf.footnotes.map((item, idx) => <React.Fragment key={idx}>
                <dt key={`dt-${idx}`}>{item.label}</dt>
                <dd key={`dd-${idx}`} dangerouslySetInnerHTML={{ __html: item.content }} />
              </React.Fragment>)
            }
          </dl>
        </ProjectMeta>
        }
      </Project>
    </Page>
  )
}