// Instead of refactoring Video and potentially adding regressions at the 11th hour, I'm 
// creating a new component to be just used for the Project Heros. 

import React, { useRef,/*useState,*/ useEffect, useCallback } from 'react';
import MediaContainer from './MediaContainer';

export default ({ video, poster, posterPortrait, className, defaultAspectRatio, onLoad, active=true })  => {
  // const [ isLoaded, setIsLoaded ] = useState(false);
  const landscapeRef = useRef();

  const isLandscape = document.body.clientWidth >= document.body.clientHeight;
  const source = !isLandscape && video.portrait ? video.portrait : video.landscape;
  const aspectRatio = source ? source.width / source.height : defaultAspectRatio;


  const canPlayThrough = useCallback((a) => {
    setTimeout(() => {
      // setIsLoaded(true);
      if (onLoad) {
        onLoad();
      }
    }, 5); // make sure animation is triggered
  }, [/*setIsLoaded,*/ onLoad]);

  useEffect(() => {
    const lCur = landscapeRef.current;

    if (lCur) {
      lCur.addEventListener('canplaythrough', canPlayThrough);
    }

    return () => {
      if (lCur) {
        lCur.removeEventListener('canplaythrough', canPlayThrough);
      }
    }
  }, [ landscapeRef, canPlayThrough ]);

  return <MediaContainer className={className} aspectRatio={aspectRatio}>
      { active && <video  ref={landscapeRef}  muted autoPlay playsInline loop src={source.url}  /> }
    </MediaContainer>
}