import styled from 'styled-components';
import React from 'react';

import MediaBlock from './MediaBlock';

const ImageGroup = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  // margin: 72px 0;
  width: 100%;

  @media (max-width: calc(${props => props.theme.bp.medium} - 1px)) {
    .alpha {
      margin-bottom: 16px;
    }
    .alpha, .beta {
      >:first-child:not(:last-child) {
        margin-bottom: 16px;
      }
      >p {
        padding: 1em 0;
      }
    }

    &.noText {
      &.group1 .alpha, &.group3 .beta {
        >:first-child {
          margin-bottom: 0;
        }
        >:last-child {
          display: none;
        }
      } 

      &.group2 .beta {
        >:first-child {
          display: none;
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.bp.medium}) {
    .alpha, .beta {
      display: flex;
      margin: 8px 0;
    }

    &.group1 {
      margin: 64px 0;
      padding: 0 4.02635%;

      .alpha {
        justify-content: flex-end;
        align-items: center;

        >:first-child {
          width: 57.8025%;
        }

        >:last-child {
          padding: 0 0 0 9.7134%;
          width: 33.758%;
        }
      }

      .beta {
        justify-content: flex-end;

        >:first-child {
          width: 74.6815%;
        }        
      }

      &.flipX {
        .alpha {
          flex-direction: row-reverse;
          justify-content: flex-start;

          >:last-child {
            padding: 0 9.7133867925% 0 0;
          }
        }

        .beta {
          flex-direction: row-reverse;
          padding-left: 8.4395%;
        }
      }

      &.flipY {
        flex-direction: column-reverse;
      }
    }

    &.group2 {
      flex-direction: row;
      justify-content: center;
      padding: 0 11.7862%;

      .alpha, .beta {
        flex-direction: column;
        width: 49.2337%;
        margin: -8px 0;
        padding: 0 8px;
        box-sizing: content-box;
        flex: none;

        >* {
          margin: 8px 0;
          width: 100%;
        }
      }

      .alpha {
        align-items: flex-end;
        >:last-child {
          width: 79.3774%;
        }
      }

      .beta {
        >:first-child {
          align-self: center;
          width: 58.7548%;
          padding: 12.2568% 0;
        }
      }

      &.flipX {
        flex-direction: row-reverse;

        .alpha {
          align-items: flex-start;
        }
      }

      &.flipY {
        .alpha, .beta {
          flex-direction: column-reverse;
        }
      }
    }

    &.group3 {
      flex-direction: row;
      justify-content: center;
      padding: 0 11.7862%;

      .alpha, .beta {
        flex-direction: column;
        flex: none;
        margin: 0;
        padding: 0 8px;
        box-sizing: content-box;
      }

      .alpha {
        width: 59.387%;
      }

      .beta {
        width: 39.0804%;
        justify-content: flex-end;

        >:last-child {
          padding: 27.2059% 0 19.2402%;
          width: 74.0196%;
          align-self: flex-end;
        }

      }

      &.flipX {
        flex-direction: row-reverse;
        .beta {
          >:last-child {
            align-self: flex-start;
          }
        }        
      }

      &.flipY {
        .alpha, .beta {
          flex-direction: column-reverse;
        }
      }
    }
  }

`

const Text = styled.p`
  font-weight: 200;
  font-size: 22px;
  line-height: 1.36364em;
  margin-bottom: 0;

  @media (min-width: ${props => props.theme.bp.medium}) {
    font-size: 20px;
  }

  @media (min-width: ${props => props.theme.bp.xlarge}) {
    font-size: 1.61054vw;
  }

`

const Group1 = ({ image1, image2, video1, video2, text }) => {
  return <>
    <div className="alpha">
      <MediaBlock image={image1} video={video1} />
      <Text>{text}</Text>
    </div>
    <div className="beta">
      <MediaBlock image={image2} video={video2} />
    </div>
  </>
}

const Group2 = ({ image1, image2, image3, video1, video2, video3, text }) => {
  return <>
    <div className="alpha">
      <MediaBlock image={image1} video={video1} />
      <MediaBlock image={image2} video={video2} />
    </div>
    <div className="beta">
      <Text>{text}</Text>
      <MediaBlock image={image3} video={video3} />
    </div>
  </>
}

const Group3 = ({ image1, image2, video1, video2, text }) => {
  return <>
    <div className="alpha">
      <MediaBlock image={image1} video={video1} />
    </div>
    <div className="beta">
      <MediaBlock image={image2} video={video2} />
      <Text>{text}</Text>
    </div>
  </>
}


const GroupComponent = {
  group1: Group1,
  group2: Group2,
  group3: Group3
}

export default ({ layout, text, image1, image2, image3, video1, video2, video3, flipX, flipY }) => {
  const Group = GroupComponent[layout];

  return <ImageGroup className={`${layout} ${flipX ? 'flipX' : ''} ${flipY ? 'flipY' : ''} ${text ? '' : 'noText'}`}>
    <Group {...{text, image1, image2, image3, video1, video2, video3}} />

  </ImageGroup>

}