import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  width: 100vw;
  height: 100vh;

  .heroItem {
    width: 100%;
    height: 100%;
    video, img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`

export default ({ children })  => {
  return <HeroContainer>{ children }</HeroContainer>;
}
