import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import WP from '../services/wordpress';
import Page from './Page';
import HtmlBlock from '../components/HtmlBlock';

const Career = styled.section`
  padding-bottom: 64px;
`
export default () => {
  const [ page, setPage ] = useState();
  const [ careers, setCareers ] = useState([]);

  useEffect(() => {
    WP.careersPage().then(result => {
      setPage(result);
    });
  }, [ setPage ]);

  useEffect(() => {
    WP.careers().then(result => {
      setCareers(result);
    });
  }, [ setCareers ]);

  return (
    <Page title="Careers">
      { page && <Career><HtmlBlock html={page.content.rendered} /></Career> }
      { careers.map((career, idx) => <Career key={idx}><HtmlBlock html={career.content.rendered} readMore /></Career>)}
    </Page>
  )
}