import styled from 'styled-components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TransitionBlock from './TransitionBlock';
import Image from './Image';

const ProjectThumb = styled.div`
  max-width: 1044px;
  width: 100%;

  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;
    width: 100%;
  }

  label {
    color: black;
    font-size: 16px;
    line-height: 17px;
    margin-top: 16px;
    align-self: flex-end;
  }
`


export default ({ project })  => {
  const [ isVisible, setIsVisible ] = useState(false);
  const [ isLoaded, setIsLoaded ] = useState(false);

  return (
    <ProjectThumb className="projectThumb">
      <TransitionBlock opacityDuration="0.4s" offsetDuration="0.4s" fromOffsetY={40} threshold={0} onShow={() => setIsVisible(true)} active={isLoaded}>
        <Link to={`/project/${project.slug}`}>
          <Image src={project?.acf.thumbnail_image} defaultAspectRatio={2560/1496} onLoad={() => setIsLoaded(true)} active={isVisible} />
          <label>{ project.title.rendered }</label>
        </Link>
      </TransitionBlock>
    </ProjectThumb>
  )
}
