import { ThemeProvider } from 'styled-components';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import Projects from './pages/Projects';
import Project from './pages/Project';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import About from './pages/About';

const theme = {
  bp: {
    small: 0,
    medium: "640px",
    large: "1024px",
    xlarge: "1200px",
    xxlarge: "1440px"
  }
}

// https://www.youtube.com/watch?v=NUQkajBdnmQ
// https://reacttraining.com/react-router/web/example/animated-transitions

export default () => {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/work" component={Projects} exact />
        <Route path="/project/:slug" component={Project} exact />
        <Route path="/about" component={About} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/careers" component={Careers} exact />
      </Switch>
    </Router>
    </ThemeProvider>
  );
}
